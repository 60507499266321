import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import MainLanding from "./components/MainLanding";
import CTOLanding from "./components/CTO/CTOLanding";
import Nav from "./components/Navbar/Nav";
import MobileNav from "./components/Navbar/MobileNav";

function App() {
  const [navHeight, setNavHeight] = useState("0%");
  const [displayMobileNav, setDisplayMobileNav] = useState(false);

  // Mobile navigation functionality
  const toggleMobileMenu = () => {
    if (displayMobileNav) {
      setNavHeight("0%");
      setDisplayMobileNav(false);
    } else {
      setNavHeight("100%");
      setDisplayMobileNav(true);
    }
  };

  const openNav = () => setNavHeight("100%");
  const closeNav = () => setNavHeight("0%");

  return (
    <Router>
      <Nav toggleMobileMenu={toggleMobileMenu} />
      <MobileNav
        openNav={openNav}
        closeNav={closeNav}
        navHeight={navHeight}
        toggleMobileMenu={toggleMobileMenu}
        displayMobileNav={displayMobileNav}
      />
      <Routes>
        <Route path="/" element={<MainLanding />} />
        <Route path="/ctosupport" element={<CTOLanding />} />
      </Routes>
    </Router>
  );
}

export default App;