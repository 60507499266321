import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Footer.css";
// import MapMarker from "../../images/map_marker.svg"
import AtIcon from "../../images/at_icon.svg"
import LinkedInIcon from "../../images/linkedin_icon.svg"
// import TwitterIcon from "../../images/twitter_logo.svg"
import { 
    useLoadScript, 
    // GoogleMap, 
    // Marker
} from "@react-google-maps/api";

const Footer = (props) => {
    
    const currentDate = new Date().getFullYear();
    const copyrightDate = currentDate + `-` + (currentDate + 1)

    // const mapContainerStyle = {
    //     width: "100vw",
    //     height: "50vh"
    // };
    // const center = {
    //     lat: 37.78779430527337,
    //     lng: -122.42455780754571
    // };
    // const options = {
    //     disableDefaultUI: true,
    //     mapId: "46174c966ff84b68"
    // }

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyAUyU1o-mEIX3_pNUlGJA942RFvdzy3vEc"
    })

    if (loadError) return "Error Loading Maps";
    if (!isLoaded) return "Loading Maps";

    const openLinkedIn = () => {
        window.open("https://www.linkedin.com/company/bluerocketinc/mycompany/")
    }
    // const openTwitter = () => {
    //     window.open("https://twitter.com/BlueRocketInc")
    // }
    // const goToGoogleMaps = () => {
    //     window.open("https://goo.gl/maps/gCN6Lm8Wv3VhXncK6")
    // }

     return (
         <div onMouseOver={() => props.setDiv("footerDiv")} >
            <a name="footer"></a>
            {/* <GoogleMap 
                mapContainerStyle={mapContainerStyle} 
                mapId="46174c966ff84b68"
                zoom={14} 
                center={center} 
                options={options}>
                <Marker 
                    position={{lat: center.lat, lng: center.lng}}
                    icon={MapMarker}
                    onClick={goToGoogleMaps}
                />
            </GoogleMap> */}
            <div className="footerDivText">
                <Row style={{
                    marginBottom: "6rem",
                    marginTop: "1rem"
                }}>
                    <Col lg="6" style={{padding: 0, marginBottom: 10 }} >
                        <Row className="hoverOpacity">
                            <Col xs="1" style={{ padding: 0 }}>
                                <img 
                                    src={AtIcon} 
                                    style={{ height: 20 }}
                                    alt="at symbol"
                                />
                            </Col>
                            <Col style={{paddingLeft: 5}}>
                                <a href="mailto:inquiries@bluerocket.us">
                                    <p style={{ display: "inline" }} className="footerText">inquiries@bluerocket.us</p>
                                </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="1" style={{ padding: 0 }}></Col>
                            <Col xs="10" style={{paddingLeft: 5}}>
                                <p className="footerSubText">As scammers are prolific, Blue Rocket will ONLY communicate through its official email domain @bluerocket.us. If you are not emailing with us directly, you are not speaking to Blue Rocket.</p>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="3" style={{padding: 0, marginBottom: 10 }}>
                        <div onClick={openLinkedIn} style={{ marginBottom: 10 }}>
                            <Row className="hoverOpacity">
                                <Col xs="1" style={{ padding: 0 }}>
                                    <img 
                                        src={LinkedInIcon} 
                                        style={{ height: 20 }}
                                        alt="linkedin icon"
                                    />
                                </Col>
                                <Col style={{paddingLeft: 5}}>
                                    <p style={{ display: "inline" }} className="footerText smallFooterImageText">Blue Rocket, Inc</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    
                    {/* <Col lg="3" style={{padding: 0, marginBottom: 10 }}>
                        <div onClick={openTwitter}>
                            <Row className="hoverOpacity">
                                <Col xs="1" style={{ padding: 0 }}>
                                    <img 
                                        src={TwitterIcon} 
                                        style={{ height: 20 }}
                                        alt="twitter icon"
                                    />
                                </Col>
                                <Col style={{paddingLeft: 5}}>
                                    <p style={{ display: "inline" }} className="footerText smallFooterImageText">@BlueRocketInc</p>
                                </Col>
                            </Row>
                        </div>
                    </Col> */}
                </Row>
                <Row>
                    <Col>
                        <p style={{ fontSize: 12, color: "white"}}>© {copyrightDate} Blue Rocket®. All Rights Reserved.</p>
                    </Col>
                </Row>
            </div>
         </div>
     )
}

export default Footer;