import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./CTO.css"
import Planetoid from "../../images/planetoid.svg";
import AstroRight from "../../images/astronaut_right.png"


const CTOLanding = () => {
  return (
    <>
        <img src={Planetoid} className="planetoid planetoidFloat bounce-2" alt="planetoid" />
        <div className="CTOLanding">
            <h1 className="pageHeader">On-Demand CTO</h1>
            <p className="pageBlurb">Our goal is to help founders and growing businesses not only imagine but also strategically plan how technology can expand their vision, drive innovation, and create a competitive edge.</p>
        
            <Row className="funkyBoxSection">
                <Row>
                    <h2 className="subHeader">Free Consultation</h2>
                </Row>
                <Row className="funkyBoxRow">
                    <Col className="funkyBox consultBox" sm={12} md={6} lg={4}>
                        <h2 className="funkyBoxHeader">Vision Building</h2>
                        <p className="funkyBoxBlurb">
                            Look out to the ultimate expression of your mission and work backwards to establish milestones and technologies needed to accomplish your vision.
                        </p>
                    </Col>
                    <Col className="funkyBox consultBox" sm={12} md={6} lg={4}>
                        <h2 className="funkyBoxHeader">Customer Journey Mapping</h2>
                        <p className="funkyBoxBlurb">
                            Visualize how technology can enhance customer experiences.
                        </p>
                    </Col>
                    <Col className="funkyBox consultBox"sm={12} md={6} lg={4}>
                        <h2 className="funkyBoxHeader">Technology Brainstorming</h2>
                        <p className="funkyBoxBlurb">
                            Review the most transformative technologies of our time and harness them to drive your goals.
                        </p>
                    </Col>
                </Row>
            </Row>

            <Row className="funkyBoxSection monthlySectionSmall">
                <Row>
                    <h2 className="subHeader">Monthly Support</h2>
                </Row>
                <Row className="funkyBoxRow monthlyRowSmall">
                    <Col className="funkyBox monthlyBox" xs={12} md={6} lg={6} >
                        <h2 className="funkyBoxHeader">Stakeholder Management</h2>
                        <p className="funkyBoxBlurb">Communicate technology strategy and progress to investors and stakeholders.</p>
                    </Col> 
                    <Col className="funkyBox monthlyBox" xs={12} md={6} lg={6}>
                        <h2 className="funkyBoxHeader">Technology Roadmap</h2>
                        <p className="funkyBoxBlurb">Develop and maintain a technology roadmap that aligns with business objectives and ensures competitive advantage.</p>
                    </Col>
                </Row>
                <Row className="funkyBoxRow">
                <Col className="funkyBox monthlyBox" xs={12} md={6} lg={6}>
                        <h2 className="funkyBoxHeader">Executive Collaboration</h2>
                        <p className="funkyBoxBlurb">Work closely with the executive team to align technology initiatives with business goals.</p>
                    </Col>
                    <Col className="funkyBox monthlyBox" xs={12} md={6} lg={6}>
                        <h2 className="funkyBoxHeader">Action Plan Development</h2>
                        <p className="funkyBoxBlurb">Create a detailed action plan to integrate the most promising technologies. Set short-term and long-term goals, assign responsibilities, and establish timelines.</p>
                    </Col>
                </Row>
            </Row>
            
            <Row className="funkyBoxSection monthlySectionLarge ">
                <Row>
                    <h2 className="subHeader">Monthly Support</h2>
                </Row>
                <Row className="funkyBoxRow" style={{ marginBottom: 20}}>
                    <Col className="funkyBox monthlyBox" xs={6} sm={6} md={6} lg={6} >
                        <h2 className="funkyBoxHeader">Stakeholder Management</h2>
                        <p className="funkyBoxBlurb">Communicate technology strategy and progress to investors and stakeholders.</p>
                    </Col> 
                    <Col className="funkyBox monthlyBox" xs={6} sm={6} md={6} lg={6}>
                        <h2 className="funkyBoxHeader">Technology Roadmap</h2>
                        <p className="funkyBoxBlurb">Develop and maintain a technology roadmap that aligns with business objectives and ensures competitive advantage.</p>
                    </Col>
                    <Col className="funkyBox monthlyBox" xs={6} sm={6} md={6} lg={6}>
                        <h2 className="funkyBoxHeader">Executive Collaboration</h2>
                        <p className="funkyBoxBlurb">Work closely with the executive team to align technology initiatives with business goals.</p>
                    </Col>
                    <Col className="funkyBox monthlyBox" xs={6} sm={6} md={6} lg={6}>
                        <h2 className="funkyBoxHeader">Action Plan Development</h2>
                        <p className="funkyBoxBlurb">Create a detailed action plan to integrate the most promising technologies. Set short-term and long-term goals, assign responsibilities, and establish timelines.</p>
                    </Col>
                </Row>
            </Row>

            <Row className="funkyBoxSection weeklySection">
                <Row>
                    <h2 className="subHeader">Weekly Support</h2>
                </Row>
                <Row className="funkyBoxRow weeklyBoxRow">
                    <Col className="funkyBox weeklyBox" xs={12} md={4}>
                        <h2 className="funkyBoxHeader">Innovation and R&D</h2>
                        <p className="funkyBoxBlurb">Drive innovation, research, and development of new technologies and solutions.</p>
                    </Col>
                    <Col className="funkyBox weeklyBox" xs={12} md={4}>
                        <h2 className="funkyBoxHeader">Performance Management</h2>
                        <p className="funkyBoxBlurb">Monitor and evaluate the performance of technology teams and individual team members. Define and track KPIs to measure the success of technology initiatives.</p>
                    </Col>
                    <Col className="funkyBox weeklyBox" xs={12} md={4}>
                        <h2 className="funkyBoxHeader">Process Optimization</h2>
                        <p className="funkyBoxBlurb">Implement and optimize processes to improve efficiency and productivity.</p>
                    </Col>
                </Row>
            </Row>
            
            <Row className="astronautInformationRow">
                <Col md="8" style={{ padding: "0px 0px 0px 0px"}}>
                    <Row className="funkyBoxSection weeklyBoxRowTwo">
                        <Row className="funkyBoxRow">
                            <Col className="funkyBox weeklyBox" xs={12} md={6}>
                                <h2 className="funkyBoxHeader">Team Building and Mentoring</h2>
                                <p className="funkyBoxBlurb">Recruit, train, and mentor technology teams. Foster a culture of continuous improvement and innovation.</p>
                            </Col>
                            <Col className="funkyBox weeklyBox" xs={12} md={6}>
                                <h2 className="funkyBoxHeader">Project Management</h2>
                                <p className="funkyBoxBlurb">Oversee major technology projects, ensuring they are delivered on time, within scope, and budget.</p>
                            </Col>
                        </Row>
                    </Row>
                    
                    <Row className="funkyBoxSection adhocSection">
                        <Row>
                            <h2 className="subHeader">Adhoc Missions</h2>
                        </Row>
                        <Row className="funkyBoxRow">
                            <Col className="funkyBox adhocBox" xs={12} md={6}>
                                <h2 className="funkyBoxHeader">SWOT Analysis</h2>
                                <p className="funkyBoxBlurb">Identify the strengths, weaknesses, opportunities, and threats related to technology in your business.</p>
                            </Col>
                            <Col className="funkyBox adhocBox" xs={12} md={6}>
                                <h2 className="funkyBoxHeader">Competitive Benchmarking</h2>
                                <p className="funkyBoxBlurb">Understand how your competitors are using technology to gain a market advantage.</p>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                    <Col style={{ padding: 1 }}>
                        <img src={AstroRight} className="ctoAstronautRight" alt="astronaut" />
                    </Col>
            </Row>
            <a href="mailto:ctoservices@bluerocket.us" style={{ color: "white" }}>
                <p className="CTAButton">Get in touch</p>
            </a>
        </div>
    </>
    
  );
};

export default CTOLanding;