import React, { useState } from "react";
import HeroBanner from "./HeroBanner/HeroBanner";
import Featured from "./Featured/Featured";
import Capabilities from "./Capabilities/Capabilities";
import Projects from "./Projects/Projects";
import FunFacts from "./FunFacts/FunFacts";
import MeetCrew from "./MeetCrew/MeetCrew";
import Footer from "./Footer/Footer";
import FeaturedDetailsModal from "./Featured/FeaturedDetails/FeaturedDetailsModal";
import UpButton from "../images/up_button.svg";
import "./MainLanding.css";
import MeetCrewMobileDetails from "./MeetCrew/MeetCrewMobileDetails";

const MainLanding = () => {
  const [showFeaturedModal, setShowFeaturedModal] = useState(false);
  const [featuredProject, setFeaturedProject] = useState();
  const [height, setHeight] = useState("0%");
  const [projectName, setProjectName] = useState();
  const [selectedFeature, setSelectedFeature] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [display, setDisplay] = useState("");
  const [profile, setProfile] = useState({});
  const [profileDetailsHeight, setProfileDetailsHeight] = useState("0%");

  // Scroll to top button functionality
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Featured projects modal functionality
  const openFeatureModal = (project) => {
    setHeight("100%");
    setDisplay("block");
    setFeaturedProject(project);
    setProjectName(project.name);
  };

  function closeFeatureModal() {
    setHeight("0%");
    setDisplay("none");
    setTimeout(function () {
      setActiveIndex(selectedFeature);
    }, 1000);
  }

  // Profile details functionality
  const openProfileDetails = (profile) => {
    setProfile({ ...profile });
    setProfileDetailsHeight("100%");
  };
  const closeProfileDetails = () => {
    setProfileDetailsHeight("0%");
  };

  return (
    <div className="mainLanding">
      <FeaturedDetailsModal
        showFeaturedModal={showFeaturedModal}
        setShowFeaturedModal={setShowFeaturedModal}
        featuredProject={featuredProject}
        setFeaturedProject={setFeaturedProject}
        projectName={projectName}
        openFeatureModal={openFeatureModal}
        closeFeatureModal={closeFeatureModal}
        height={height}
        setHeight={setHeight}
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        display={display}
      />
      <MeetCrewMobileDetails
        openProfileDetails={openProfileDetails}
        closeProfileDetails={closeProfileDetails}
        profileDetailsHeight={profileDetailsHeight}
        setProfileDetailsHeight={setProfileDetailsHeight}
        profile={profile}
      />
      <HeroBanner />
      <Featured
        openFeatureModal={openFeatureModal}
        closeFeatureModal={closeFeatureModal}
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
      />
      <Capabilities />
      <Projects />
      <FunFacts />
      <MeetCrew openProfileDetails={openProfileDetails} />
      <Footer />
      <button
        className="upButton"
        style={{ display: showScroll ? "flex" : "none" }}
        onClick={scrollTop}
      >
        <img src={UpButton} style={{ height: 50, width: 50 }} alt="to top button" />
      </button>
    </div>
  );
};

export default MainLanding;